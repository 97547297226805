<template>
  <b-card>
    <section class="questions-bank mb-2">
      <b-row align-v="center">
        <b-col class="questions-bank__filter-by-activity-container d-flex w-100">
          <ek-input-text
            v-if="questionId != 0"
            readonly
            clearable
            label="اسم الاختبار"
            v-model="quizDto.name"
            name="filter"
            class="questions-bank__activity-filter-input"
          />
        </b-col>

        <b-col class="questions-bank__filter-by-ads-status-container d-flex">
          <ek-input-select
            v-if="questionId != 0"
            label="تابع للوحدة"
            :selectable="() => false"
            v-model="quizDto.unitId"
            :options="unitsOptions"
            placeholder=" "
            name="ftiler"
            class="questions-bank__status-filter-input"
          />
        </b-col>
        <b-col class="questions-bank__filter-by-ads-status-container d-flex">
          <ek-input-text
            label="ترتيب السؤال"
            v-model="quizDto.questionOrder"
            name="ftiler"
            class="questions-bank__status-filter-input"
          />
        </b-col>
      </b-row>
    </section>
  </b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: ["quizId", "id", "lessonId", "questionId"],

  data() {
    return {};
  },
  computed: {
    ...mapState({
      quizeOptions: ({ questionsBank }) => questionsBank.quizeOptions,
      unitsOptions: ({ units }) => units.unitsOptions,
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
      lessonDto: ({ lessons }) => lessons.lessonDto,
    }),
  },
  methods: {
    ...mapActions([
      "addQuestionQuizLesson",
      "getSubjectOptions",
      "getLessonQuizeByIdSub",
      "addQuestionAnswer",
      "getQuizes",
      "getLessonQuiz",
    ]),
  },
  created() {
    console.log(this.lessonDto);
    this.getLessonQuiz(this.lessonId).then((res) => {
      this.$store.commit("SET_QUIZ_DTO", {
        unitId: res.unitId,
        name: res.name ? res.name : this.quizDto.name,
        questionOrder: this.lessonDto.questions.length + 1,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.b-icon {
  margin-left: 3px;
}

.b-icons {
  height: 21px;
  position: relative;
  left: 3px;
}

.questions-bank {
  align-items: center;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }

  &__filter-title {
    font-weight: bold;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__filter-container {
    display: flex;
    gap: 2rem;
  }

  &__filter-by-activity-container {
    display: flex;
  }

  &__activity-filter-title {
    margin-top: 1.4rem;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__activity-filter-input {
    flex: 0.9;
  }

  &__filter-by-ads-status-container {
    display: flex;
  }

  &__status-filter-title {
    margin-top: 1.4rem;

    font-size: 1.2rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__status-filter-input {
    flex: 0.94;
  }
}
</style>
