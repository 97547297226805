var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trueFalse"},[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"correct-border w-100 p-2 mb-2 rounded"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"d-flex justify-content-between"},[_vm._v(" نص السؤال ")]),_c('div',{staticClass:"group-btn-question d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.types),function(type){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:type.id,staticClass:"p-0 b-icon",attrs:{"variant":"primary","title":type.title},on:{"click":function($event){return _vm.addMedias(_vm.trueFalseDto, type)}}},[_c('unicon',{attrs:{"fill":"white","height":"18px","name":type.icon}})],1)}),1)])]),_c('ek-input-text',{staticClass:"w-75",attrs:{"placeholder":"السؤال","name":"addQuestion","rules":[
          {
            type: 'required',
            message: 'السؤال مطلوب',
          } ]},model:{value:(_vm.trueFalseDto.content),callback:function ($$v) {_vm.$set(_vm.trueFalseDto, "content", $$v)},expression:"trueFalseDto.content"}}),_vm._l((_vm.trueFalseDto.medias),function(media,index){return _c('b-col',{key:index,attrs:{"cols":"12"}},[_c('div',{staticClass:"position-relative"},[_c('ek-input-image',{attrs:{"required":true,"name":"image","image":media.url},on:{"input":function($event){media.file = $event.file},"imageDelete":function($event){return _vm.deletedMedia(index)}}},[_c('h5',[_vm._v("ادرج الملف هنا او انقر للرفع")]),_c('unicon',{attrs:{"name":media.mediaType == 1 ? 'microphone' : 'image-plus',"height":"12px"}})],1),_c('b-button',{staticClass:"position-absolute svg-delete",attrs:{"variant":"wight"},on:{"click":function($event){return _vm.deleteMedia(_vm.trueFalseDto, index)}}},[_c('svg',{staticStyle:{"cursor":"pointer"},attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z","stroke":"#EA5455","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M11.875 7.125L7.125 11.875","stroke":"#EA5455","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M7.125 7.125L11.875 11.875","stroke":"#EA5455","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])],1)])})],2),_c('div',{staticClass:"w-100 mb-1 rounded"},[_c('h4',{attrs:{"for":"question-text"}},[_vm._v("حدد الإجابة")])]),_c('div',{staticClass:"correct-border w-100 p-2 mb-2 rounded"},[_c('div',{staticClass:"shadow w-100 p-1 mt-1 rounded"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.answerOptions.map(function (el) { return ({
                value: el.id,
                text: el.text,
              }); }),"aria-describedby":ariaDescribedby,"name":"radio-options-answer"},model:{value:(_vm.trueFalseDto.answer.isTrue),callback:function ($$v) {_vm.$set(_vm.trueFalseDto.answer, "isTrue", $$v)},expression:"trueFalseDto.answer.isTrue"}})]}}])})],1),(_vm.trueFalseDto.answer.isTrue == 'false')?_c('div',{staticClass:"shadow mt-1 p-1 w-100 rounded"},[_c('ek-input-text',{attrs:{"rules":[{ type: 'required', message: ' تصحيح الجواب مطلوب' }],"label":"تصحيح الاجابة","placeholder":"صحح الإجابة الخاطئة","name":"correctionName"},model:{value:(_vm.trueFalseDto.answer.correction),callback:function ($$v) {_vm.$set(_vm.trueFalseDto.answer, "correction", $$v)},expression:"trueFalseDto.answer.correction"}})],1):_vm._e()]),_c('div',{staticClass:"shadow w-100 p-2 mt-2 rounded"},[_c('ek-input-text',{attrs:{"label":"التلميح","name":"hint","placeholder":"اضف تلميح للسؤال"},model:{value:(_vm.trueFalseDto.hint),callback:function ($$v) {_vm.$set(_vm.trueFalseDto, "hint", $$v)},expression:"trueFalseDto.hint"}})],1),_c('div',{staticClass:"ml-1 mt-3 d-flex justify-content-between"},[_c('div',[_c('b-button',{staticClass:"ml-1",attrs:{"size":"md","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.questionId != 0 ? "تعديل" : "اضافة")+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" تراجع ")])],1),(_vm.questionId != 0)?_c('div',[_c('b-button',{staticClass:"ml-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteQuestion(_vm.trueFalseDto.id)}}},[_vm._v(" حذف السؤال ")])],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }