<template>
  <div class="graph">
    <ValidationObserver ref="form">
      <div class="graph-border">
        <div class="bg-white shadow p-1 rounded">
          <ek-input-text
            placeholder="السؤال"
            name="السؤال"
            class="w-75"
            v-model="coordianteChartDto.content"
            :rules="[
              {
                type: 'required',
                message: 'السؤال مطلوب',
              },
            ]"
          >
          </ek-input-text>
        </div>

        <div
          class="w-100 d-flex justify-content-between align-items-center"
          v-for="(chartQuestion, index) in coordianteChartDto.question"
          :key="index"
        >
          <div
            class="w-100 border-line bg-white shadow p-1 rounded mt-1 d-flex justify-content-between align-items-center"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-start align-items-center">
                <h5 class="pr-3">النقطة {{ index + 1 }}:</h5>
                <ek-input-text
                  name="محور الوايات"
                  v-model="chartQuestion.y"
                ></ek-input-text>
                <span class="pl-2">Y</span>
              </div>
              <div class="d-flex justify-content-start align-items-center pl-3">
                <ek-input-text
                  name="محور الاكسات"
                  v-model="chartQuestion.x"
                ></ek-input-text>
                <span class="pl-2">X</span>
                <span class="pl-2">{{ `P${index + 1}` }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <b-button
                v-if="
                  coordianteChartDto.question[
                    index - coordianteChartDto.question.length + 1
                  ]
                "
                variant="primary"
                class="p-0 mr-2"
                @click="addPoint(coordianteChartDto.question)"
              >
                <unicon name="plus" style="fill: #fff"></unicon>
              </b-button>
              <b-button
                variant="transperant"
                class="p-0"
                @click="deletePointQuestion(coordianteChartDto.question, index)"
              >
                <svg
                  style="cursor: pointer"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.875 7.125L7.125 11.875"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.125 7.125L11.875 11.875"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </b-button>
            </div>
          </div>
        </div>
        <b-form-checkbox
          class="mt-2"
          id="checkbox-1"
          v-model="coordianteChartDto.isClosedShape"
          name="checkbox-1"
        >
          الشكل مغلق
        </b-form-checkbox>
      </div>
      <h5 class="mt-3">اضافة اجابة</h5>
      <div class="graph-border">
        <div
          class="w-100 d-flex justify-content-between align-items-center"
          v-for="(chartAnswer, index) in coordianteChartDto.answer"
          :key="index"
        >
          <div
            class="w-100 border-line bg-white shadow p-1 rounded mt-1 d-flex justify-content-between align-items-center"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-start align-items-center">
                <h5 class="pr-3">النقطة {{ index + 1 }}:</h5>
                <ek-input-text
                  name="محور الوايات"
                  v-model="chartAnswer.y"
                ></ek-input-text>
                <span class="pl-2">Y</span>
              </div>
              <div class="d-flex justify-content-start align-items-center pl-3">
                <ek-input-text
                  name="المحور الاكسات"
                  v-model="chartAnswer.x"
                ></ek-input-text>
                <span class="pl-2">X</span>
                <span class="pl-2">{{ `P${index + 1}` }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                v-if="
                  coordianteChartDto.answer[index - coordianteChartDto.answer.length + 1]
                "
                variant="primary"
                class="p-0 mr-2"
                @click="addPoint(coordianteChartDto.answer)"
              >
                <unicon name="plus" style="fill: #fff"></unicon>
              </b-button>
              <b-button
                variant="transperant"
                class="p-0"
                @click="deletePointAnswer(coordianteChartDto.answer, index)"
              >
                <svg
                  style="cursor: pointer"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.875 7.125L7.125 11.875"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.125 7.125L11.875 11.875"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow w-100 p-2 mt-2 rounded">
        <ek-input-text
          label="التلميح"
          name="hint"
          placeholder="اضف تلميح للسؤال"
          v-model="coordianteChartDto.hint"
        ></ek-input-text>
      </div>

      <div class="ml-1 mt-3 d-flex justify-content-between">
        <div>
          <b-button size="md" class="ml-1" variant="primary" @click="submitFormGraph">
            {{ questionId != 0 ? "تعديل" : "اضافة" }}
          </b-button>

          <b-button variant="outline-primary" class="ml-1" @click="$router.go(-1)">
            تراجع
          </b-button>
        </div>
        <div v-if="questionId != 0">
          <b-button
            variant="danger"
            class="ml-1"
            @click="deleteQuestion(coordianteChartDto.id)"
          >
            حذف السؤال
          </b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["quizId", "questionId"],
  computed: {
    ...mapState({
      lessonDto: ({ lessons }) => lessons.lessonDto,
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
    }),
  },
  data() {
    return {
      coordianteChartDto: {
        id: "",
        quizId: "",
        order: 0,
        isClosedShape: true,
        question: [
          {
            x: 0,
            y: 0,
          },
        ],
        answer: [
          {
            x: 0,
            y: 0,
          },
        ],
        content: "",
        hint: "",
      },
      coordianteChartList: [],
    };
  },
  methods: {
    submitFormGraph() {
      this.coordianteChartDto.order = this.quizDto.questionOrder;
      this.$refs.form.validate().then((succ) => {
        if (succ) {
          if (this.questionId != 0) {
            this.updateCoordinateChart(this.coordianteChartDto).then((res) => {
              this.coordianteChartDto = {
                ...res,
              };
            });
          } else {
            // this.coordianteChartDto.order = this.$refs.bankOrder.innerVal;
            this.addCoordinateChart(this.coordianteChartDto).then((data) => {
              this.$emit("questionAdded", data);

              this.coordianteChartList.push(this.coordianteChartDto);
              (this.coordianteChartDto.content = ""),
              (this.coordianteChartDto.hint = "");
              (this.coordianteChartDto.isClosedShape = true),
              (this.coordianteChartDto.question = [
                  {
                    x: 0,
                    y: 0,
                  },
                ]),
                (this.coordianteChartDto.answer = [
                  {
                    x: 0,
                    y: 0,
                  },
                ]);
            });
            console.log(this.coordianteChartDto);
          }
        }
      });
    },

    addPoint(item) {
      item.push({ x: 0, y: 0 });
    },

    deletePointAnswer(item, id) {
      console.log(item);
      console.log(item[0]);
      // console.log(dto);
      if (item.length > 1) {
        let s = item.findIndex((ele, index) => index == id);
        item = item.splice(s, 1);
      }
    },
    deletePointQuestion(item, id) {
      if (item.length > 1) {
        let s = item.findIndex((ele, index) => index == id);
        item = item.splice(s, 1);
      }
    },
    ...mapActions([
      "addCoordinateChart",
      "getByIdCoordinateChart",
      "updateCoordinateChart",
      "deleteQuestion",
    ]),
  },
  created() {
    this.coordianteChartDto.quizId = this.quizId;
    if (this.$route.params.questionId != 0) {
      this.getByIdCoordinateChart(this.questionId).then((res) => {
        this.coordianteChartDto = {
          ...res,
        };
      });
    }
  },
  beforeDestroy() {
    this.coordianteChartDto = {
      id: "",
      quizId: "",
      order: 0,
      isClosedShape: true,
      question: [
        {
          x: 0,
          y: 0,
        },
      ],
      answer: [
        {
          x: 0,
          y: 0,
        },
      ],
      content: "",
      hint: "",
    };
  },
};
</script>

<style lang="scss">
.graph {
  .graph-border {
    padding: 20px;
    border: 3px solid #eee;
    border-radius: 5px;
    .border-line {
      border: 3px solid #eee;
    }
  }
}
</style>
