<template>
  <div class="match">
    <ValidationObserver ref="form">
      <div class="match-border">
        <div class="bg-white shadow p-1 rounded">
          <ek-input-text
            placeholder="السؤال"
            name="السؤال"
            class="w-75"
            v-model="matchDto.content"
            :rules="[
              {
                type: 'required',
                message: 'السؤال مطلوب',
              },
            ]"
          >
          </ek-input-text>
        </div>
        <div class="border-line bg-white shadow p-1 rounded mt-1">
          <b-row class="w-100 d-flex justify-content-between align-items-center">
            <b-col lg="6" md="12">
              <span class="mr-1"> اضافة سؤال </span>
              <b-button
                variant="primary"
                class="p-0 mr-2"
                @click="addQuestionAnswer(matchDto.questions)"
              >
                <unicon name="plus" style="fill: #fff"></unicon>
              </b-button>
              <div
                class="d-flex justify-content-start align-items-center"
                v-for="(question, index) in matchDto.questions"
                :key="index"
              >
                <h4 class="mr-2">{{ index + 1 }}</h4>
                <ek-input-text
                  class="w-75"
                  name="السؤال"
                  :rules="[
                    {
                      type: 'required',
                      message: 'السؤال مطلوب',
                    },
                  ]"
                  v-model="matchDto.questions[index]"
                ></ek-input-text>
                <b-button
                  variant="transperant"
                  class="p-0 ml-2"
                  @click="deleteQuestion(matchDto.questions, index)"
                >
                  <svg
                    style="cursor: pointer"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.875 7.125L7.125 11.875"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.125 7.125L11.875 11.875"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </b-button>
              </div>
            </b-col>
            <b-col lg="6" md="12">
              <div>
                <span class="mr-1"> اضافة جواب </span>
                <b-button
                  variant="primary"
                  class="p-0 mr-2"
                  @click="addQuestionAnswer(matchDto.answers)"
                >
                  <unicon name="plus" style="fill: #fff"></unicon>
                </b-button>
              </div>
              <div
                class="d-flex justify-content-start align-items-center"
                v-for="(answer, index) in matchDto.answers"
                :key="index"
              >
                <h4 class="mr-2">{{ index + 1 }}</h4>
                <ek-input-text
                  class="w-75"
                  name="الجواب"
                  :rules="[
                    {
                      type: 'required',
                      message: 'الجواب مطلوب',
                    },
                  ]"
                  v-model="matchDto.answers[index]"
                ></ek-input-text>
                <b-button
                  variant="transperant"
                  class="p-0 ml-2"
                  @click="deleteAnswer(matchDto.answers, index)"
                >
                  <svg
                    style="cursor: pointer"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.875 7.125L7.125 11.875"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.125 7.125L11.875 11.875"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="shadow w-100 p-2 mt-2 rounded">
        <ek-input-text
          label="التلميح"
          name="hint"
          placeholder="اضف تلميح للسؤال"
          v-model="matchDto.hint"
        >
        </ek-input-text>
      </div>
      <div class="ml-1 mt-3 d-flex justify-content-between">
        <div>
          <b-button size="md" class="ml-1" variant="primary" @click="submitFormMatching">
            {{ questionId != 0 ? "تعديل" : "اضافة" }}
          </b-button>

          <b-button variant="outline-primary" class="ml-1" @click="$router.go(-1)">
            تراجع
          </b-button>
        </div>
        <div v-if="questionId != 0">
          <b-button variant="danger" class="ml-1" @click="deleteQuestion(matchDto.id)">
            حذف السؤال
          </b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["quizId", "questionId"],
  computed: {
    ...mapState({
      lessonDto: ({ lessons }) => lessons.lessonDto,
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
    }),
  },
  data() {
    return {
      matchList: [],
      matchDto: {
        quizId: "",
        order: 0,
        content: "",
        hint: "",
        medias: {
          file: "",
          mediaType: "",
          thumbFile: "",
        },
        questions: [""],
        answers: [""],
      },
    };
  },
  methods: {
    submitFormMatching() {
      this.matchDto.order = this.quizDto.questionOrder;

      this.$refs.form.validate().then((succ) => {
        if (succ) {
          if (this.questionId != 0) {
            this.updateMatching(this.matchDto).then((res) => {
              this.matchDto = {
                ...res,
              };
            });
          } else {
            // this.matchDto.order = this.$refs.bankOrder.innerVal;
            this.addMatching(this.matchDto).then((res) => {
              this.$emit("questionAdded", res);
              this.matchDto.content = "";
              this.matchDto.hint = "";
              this.matchDto.questions = [''];
              this.matchDto.answers = [''];
              (this.matchDto.medias = { file: "", mediaType: "", thumbFile: "" }),
                this.matchList.push(this.matchDto);
            });
            console.log(this.matchDto);
          }
        }
      });
    },
    addQuestionAnswer(item) {
      item.push("");
    },
    deleteAnswer(item, id) {
      let s = item.findIndex((ele, index) => index == id);
      item = item.splice(s, 1);
    },
    deleteQuestion(item, id) {
      let s = item.findIndex((ele, index) => index == id);
      item = item.splice(s, 1);
    },

    ...mapActions(["addMatching", "getByIdMatching", "updateMatching", "deleteQuestion"]),
  },
  created() {
    this.matchDto.quizId = this.quizId;
    if (this.$route.params.questionId != 0) {
      this.getByIdMatching(this.questionId).then((res) => {
        this.matchDto = {
          ...res,
        };
      });
    }
  },
};
</script>

<style lang="scss">
.match {
  .match-border {
    padding: 20px;
    border: 3px solid #eee;
    border-radius: 5px;

    .border-line {
      border: 3px solid #eee;

      h4 {
        border: 1px solid rgb(220, 220, 220);
        padding: 7px 15px;
        border-radius: 5px;
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
