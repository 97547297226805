<template>
  <div class="table">
    <!-- {{ tableDto }} -->
    <ValidationObserver ref="form">
      <!--Add question table-->
      <div class="table-border mb-2">
        <div class="bg-white shadow p-1 rounded">
          <ek-input-text
            placeholder="السؤال"
            name="السؤال"
            class="w-75"
            v-model="tableDto.content"
          >
          </ek-input-text>
        </div>
      </div>
      <!--Add Answer table-->
      <div class="w-100 mb-2 rounded">
        <h4 for="question-text">إضافة إجابة</h4>
      </div>
      <div>
        <div class="table-border d-flex">
          <div>
            <h6 for="question-text" class="m-0">
              إضافة سطر
              <b-button variant="primary" class="p-0 b-icon ml-1" @click="addRow">
                <unicon fill="white" height="18px" name="plus"> </unicon>
              </b-button>
            </h6>
          </div>
          <div class="ml-4">
            <h6 for="question-text" class="m-0">
              إضافة عمود
              <b-button variant="primary" class="p-0 b-icon ml-1" @click="addCol">
                <unicon fill="white" height="18px" name="plus"> </unicon>
              </b-button>
            </h6>
          </div>
          <div class="ml-4 d-flex align-items-center">
            <h6 for="question-text" class="m-0">حذف عمود</h6>
            <b-button variant="danger" class="p-0 b-icon ml-1" @click="deleteCol">
              <unicon fill="white" height="18px" name="minus"> </unicon>
            </b-button>
          </div>
        </div>
        <div
          class="w-100 d-flex justify-content-center align-items-center"
          v-for="(row, index1) in rowList"
          :key="index1"
        >
          <div
            v-for="(col, index2) in row"
            :key="index2"
            class="w-100 border-line bg-light p-1 rounded mt-1 d-flex justify-content-center align-items-center"
          >
            <div class="d-flex justify-content-between align-items-center">
              <ek-input-text name=" point" v-model="col.value"></ek-input-text>

              <b-button
                variant="primary"
                class="p-0 b-icon ml-1"
                @click="col.isHidden = !col.isHidden"
              >
                <unicon
                  fill="white"
                  height="18px"
                  :name="col.isHidden ? 'eye-slash' : 'eye'"
                >
                </unicon>
              </b-button>
            </div>
          </div>
          <b-button
            variant="transperant"
            class="pr-1 mt-2"
            @click="deleterow(rowList, index1)"
          >
            <svg
              style="cursor: pointer"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                stroke="#EA5455"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.875 7.125L7.125 11.875"
                stroke="#EA5455"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.125 7.125L11.875 11.875"
                stroke="#EA5455"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </b-button>
        </div>
      </div>
      <!--Add hint-->
      <div class="shadow w-100 p-2 mt-2 rounded">
        <ek-input-text
          label="التلميح"
          name="hint"
          placeholder="اضف تلميح للسؤال"
          v-model="tableDto.hint"
        >
        </ek-input-text>
      </div>
      <!-- Add Button Footer-->

      <div class="ml-1 mt-3 d-flex justify-content-between">
        <div>
          <b-button size="md" class="ml-1" variant="primary" @click="submitTable">
            {{ questionId != 0 ? "تعديل" : "اضافة" }}
          </b-button>

          <b-button variant="outline-primary" class="ml-1" @click="$router.go(-1)">
            تراجع
          </b-button>
        </div>
        <div v-if="questionId != 0">
          <b-button variant="danger" class="ml-1" @click="deleteQuestion(tableDto.id)">
            حذف السؤال
          </b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["quizId", "questionId"],
  computed: {
    ...mapState({
      lessonDto: ({ lessons }) => lessons.lessonDto,
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
    }),
  },
  data() {
    return {
      tableDto: {
        id: "",
        quizId: "",
        medias: [],
        content: "",
        hint: "",
        table: [],
      },
      tableList: [],
      types: [
        {
          id: 1,
          icon: "microphone",
          title: "microphone",
        },
        {
          id: 2,
          icon: "image-plus",
          title: "image",
        },
      ],
      colsPerRow: 2,

      rowList: [
        [
          { value: "", isHidden: false },
          { value: "", isHidden: false },
        ],
        [
          { value: "", isHidden: false },
          { value: "", isHidden: false },
        ],
      ],
    };
  },
  methods: {
    ...mapActions(["getByIdTable", "updateTable", "addTable", "deleteQuestion"]),
    addRow() {
      this.rowList.push([]);
      for (let index = 0; index < this.colsPerRow; index++) {
        this.rowList[this.rowList.length - 1].push({
          value: "",
          isHidden: false,
        });
      }
    },
    addCol() {
      this.colsPerRow++;
      for (let row of this.rowList) {
        row.push({
          value: "",
          isHidden: false,
        });
      }
    },
    deleterow(item, id) {
      let row = item.findIndex((el, item) => item == id);
      item = item.splice(row, 1);
    },
    deleteCol() {
      if (this.colsPerRow > 1) {
        this.colsPerRow = this.colsPerRow - 1;
        this.rowList = this.rowList.map((row) => {
          row.pop();
          return row;
        });
      }
    },
    submitTable() {
      this.tableDto.order = this.quizDto.questionOrder;
      this.$refs.form.validate().then((succ) => {
        if (succ) {
          if (this.questionId != 0) {
            this.updateTable(this.tableDto).then((res) => {
              this.tableDto = {
                ...res,
              };
            });
          } else {
            this.tableDto.table = [...this.rowList];
            // this.tableDto.order = this.$refs.bank.Order.innerVal;
            this.addTable(this.tableDto).then((data) => {
              this.$emit("questionAdded", data);
              (this.tableDto.content = ""),
                (this.tableDto.hint = ""),
                (this.tableDto.medias = [""]),
                (this.tableDto.table = [""]),
                (this.rowList = [
                  {
                    value: "",
                    isHidden: false,
                  },
                ]);
            });
          }
        }
      });
    },
  },
  created() {
    this.tableDto.quizId = this.quizId;
    if (this.$route.params.questionId != 0) {
      this.getByIdTable(this.questionId).then((res) => {
        this.tableDto = {
          ...res,
        };
        this.rowList = res.table;
        if (res.table.length) this.colsPerRow = res.table[0].length;
      });
    }
  },
};
</script>

<style lang="scss">
.table {
  .table-border {
    padding: 20px;
    border: 3px solid #eee;
    border-radius: 5px;

    .border-line {
      border: 3px solid #eee;

      h4 {
        border: 1px solid rgb(220, 220, 220);
        padding: 7px 15px;
        border-radius: 5px;
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
