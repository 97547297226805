<template>
  <div class="add-question">
    <infoAddQuestion :lessonId="quizId"></infoAddQuestion>
    <b-card>
      <b-card-header class="pb-2">
        <h4>إضافة سؤال</h4>
        <div class="group-btn-question d-flex justify-content-between align-items-center">
          <div class="d-flex" v-if="questionId == 0">
            <b-button
              v-for="type in types"
              :key="type.id"
              variant="primary "
              class="p-0 b-icon"
              @click="setActiveComponent(type)"
              v-b-tooltip.hover
              :title="type.title"
            >
              <unicon fill="white" height="18px" :name="type.icon"> </unicon>
            </b-button>
          </div>
        </div>
      </b-card-header>
      <b-card-body>
        <component
          @questionAdded="onQuestionAdded"
          :is="activeValue.component"
          :quizId="quizId"
          :questionId="questionId"
        ></component>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import infoAddQuestion from "@admin/questionsBank/components/addQuestionButton/infoAddQuestion.vue";
import defaultForQuiz from "@admin/questionsBank/components/addQuestionButton/addQuestion/main.vue";
import TrueFalse from "@admin/questionsBank/components/addQuestionButton/addQuestion/correct.vue";
import matching from "@admin/questionsBank/components/addQuestionButton/addQuestion/connection.vue";
import CoordinateChart from "@admin/questionsBank/components/addQuestionButton/addQuestion/graph.vue";
import table from "@admin/questionsBank/components/addQuestionButton/addQuestion/table.vue";
import Written from "@admin/questionsBank/components/addQuestionButton/addQuestion/written.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    infoAddQuestion,
    CoordinateChart,
    table,
    defaultForQuiz,
    TrueFalse,
    matching,
    Written,
  },
  props: ["quizId", "questionId"],
  data() {
    return {
      activeValue: {
        id: 2,
        icon: "question-circle",
        component: defaultForQuiz,
      },
      types: [
        {
          id: 2,
          icon: "question-circle",
          component: defaultForQuiz,
          title: "Question",
        },
        {
          id: 3,
          icon: "chart-line",
          component: CoordinateChart,
          title: "chart-line",
        },
        {
          id: 4,
          icon: "arrow-random",
          component: matching,
          title: "Matching",
        },
        {
          id: 5,
          icon: "table",
          component: table,
          title: "Table",
        },
        {
          id: 6,
          icon: "check-circle",
          component: TrueFalse,
          title: "TrueFalse",
        },
        {
          id: 7,
          icon: "pen",
          component: Written,
          title: "Written",
        },
      ],
    };
  },
  methods: {
    setActiveComponent(obj) {
      this.activeValue = obj;
    },
    onQuestionAdded(data) {
      console.log("onQuestionAdded", data);
      this.unitLessonDto.questions.push(data);
    },
    ...mapActions(["getQuizes"]),
  },
  computed: {
    ...mapState({
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
      unitLessonDto: ({ units }) => units.unitLessonDto,
    }),
  },
  created() {
    // this.getQuizes();
    this.activeValue = this.types.find((ele) => ele.id == this.$route.query.questionType);
    if (this.quizId) {
      this.quizDto.quizId = this.quizId;
    }
  },
  beforeDestroy() {
    this.$store.commit("RESET_QUIZ_DTO");
  },
};
</script>

<style lang="scss">
.group-btn-question {
  // padding-bottom: 20px;
  button {
    margin-right: 5px;
    padding: 2px !important;
  }
}
</style>
