<template>
  <div class="trueFalse">
    <ValidationObserver ref="form">
      <!-- Add Questions -->
      <div class="correct-border w-100 p-2 mb-2 rounded">
        <div class="d-flex justify-content-between">
          <label class="d-flex justify-content-between"> نص السؤال </label>
          <div
            class="group-btn-question d-flex justify-content-between align-items-center"
          >
            <div class="d-flex">
              <!--type Medias-->
              <b-button
                v-for="type in types"
                :key="type.id"
                variant="primary"
                class="p-0 b-icon"
                v-b-tooltip.hover
                :title="type.title"
                @click="addMedias(trueFalseDto, type)"
              >
                <unicon fill="white" height="18px" :name="type.icon"> </unicon>
              </b-button>
            </div>
          </div>
        </div>
        <ek-input-text
          placeholder="السؤال"
          name="addQuestion"
          v-model="trueFalseDto.content"
          class="w-75"
          :rules="[
            {
              type: 'required',
              message: 'السؤال مطلوب',
            },
          ]"
        >
        </ek-input-text>
        <b-col cols="12" v-for="(media, index) in trueFalseDto.medias" :key="index">
          <div class="position-relative">
            <!-- Add input image for media-->
            <ek-input-image
              :required="true"
              name="image"
              :image="media.url"
              @input="media.file = $event.file"
              @imageDelete="deletedMedia(index)"
            >
              <h5>ادرج الملف هنا او انقر للرفع</h5>
              <unicon
                :name="media.mediaType == 1 ? 'microphone' : 'image-plus'"
                height="12px"
              ></unicon>
            </ek-input-image>
            <!--Add button delete media-->
            <b-button
              variant="wight"
              class="position-absolute svg-delete"
              @click="deleteMedia(trueFalseDto, index)"
            >
              <svg
                style="cursor: pointer"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.875 7.125L7.125 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.125 7.125L11.875 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </b-button>
          </div>
        </b-col>
      </div>
      <!--Add Answer-->
      <div class="w-100 mb-1 rounded">
        <h4 for="question-text">حدد الإجابة</h4>
      </div>
      <div class="correct-border w-100 p-2 mb-2 rounded">
        <div class="shadow w-100 p-1 mt-1 rounded">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              :options="
                answerOptions.map((el) => ({
                  value: el.id,
                  text: el.text,
                }))
              "
              :aria-describedby="ariaDescribedby"
              v-model="trueFalseDto.answer.isTrue"
              name="radio-options-answer"
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div
          v-if="trueFalseDto.answer.isTrue == 'false'"
          class="shadow mt-1 p-1 w-100 rounded"
        >
          <ek-input-text
            :rules="[{ type: 'required', message: ' تصحيح الجواب مطلوب' }]"
            label="تصحيح الاجابة"
            v-model="trueFalseDto.answer.correction"
            placeholder="صحح الإجابة الخاطئة"
            name="correctionName"
          >
          </ek-input-text>
        </div>
      </div>
      <!--Add hint-->
      <div class="shadow w-100 p-2 mt-2 rounded">
        <ek-input-text
          label="التلميح"
          name="hint"
          placeholder="اضف تلميح للسؤال"
          v-model="trueFalseDto.hint"
        >
        </ek-input-text>
      </div>
      <!--Add Button Footer-->

      <div class="ml-1 mt-3 d-flex justify-content-between">
        <div>
          <b-button size="md" class="ml-1" variant="primary" @click="submit">
            {{ questionId != 0 ? "تعديل" : "اضافة" }}
          </b-button>

          <b-button variant="outline-primary" class="ml-1" @click="$router.go(-1)">
            تراجع
          </b-button>
        </div>
        <div v-if="questionId != 0">
          <b-button
            variant="danger"
            class="ml-1"
            @click="deleteQuestion(trueFalseDto.id)"
          >
            حذف السؤال
          </b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["questionId", "quizId"],
  computed: {
    ...mapState({
      lessonDto: ({ lessons }) => lessons.lessonDto,
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
    }),
  },
  data() {
    return {
      trueFalseDto: {
        quizId: "",
        content: "",
        hint: "",
        order: "",
        deletedMedias: [],
        answer: {
          isTrue: "",
          correction: "",
        },
        medias: [],
      },
      trueFalseList: [],
      types: [
        {
          id: 1,
          icon: "microphone",
          title: "microphone",
        },
        {
          id: 2,
          icon: "image-plus",
          title: "image",
        },
      ],
      answerOptions: [
        {
          id: "true",
          text: " صحيحة ",
        },
        {
          id: "false",
          text: "خاطئة",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getByIdTrueFalse",
      "updateTrueFalse",
      "addTrueFalse",
      "decryptFile",
      "deleteQuestion",
    ]),
    addMedias(target, type) {
      target.medias.push({
        file: null,
        mediaType: type.id,
        thumbFile: null,
      });
    },
    deleteMedia(index, id) {
      var md = index.medias.findIndex((el, index) => index == id);
      index = index.medias.splice(md, 1);
    },
    deletedMedia(index) {
      this.trueFalseDto.deletedMedias.push(index);
    },
    submit() {
      this.trueFalseDto.order = this.quizDto.questionOrder;
      this.$refs.form.validate().then((succ) => {
        if (succ) {
          if (this.questionId != 0) {
            this.updateTrueFalse(this.trueFalseDto).then((res) => {
              this.trueFalseDto = { ...res };
              this.trueFalseDto.answer.correction = "";
            });
          } else {
            // this.trueFalseDto.order = this.$refs.bankOrder.innerVal;
            this.addTrueFalse(this.trueFalseDto).then((data) => {
              this.$emit("questionAdded", data);
              this.trueFalseList.push(this.trueFalseDto);
              (this.trueFalseDto.content = ""),
                (this.trueFalseDto.hint = ""),
                (this.trueFalseDto.deleteMedia = [""]),
                (this.trueFalseDto.medias = [""]),
                this.trueFalseDto.answer.correction = "";
            });
          }
        }
      });
    },
  },
  created() {
    this.trueFalseDto.quizId = this.quizId;
    if (this.questionId != 0) {
      this.getByIdTrueFalse(this.questionId).then((res) => {
        this.trueFalseDto = { ...res, medias: res.urls };
        console.log("correct", res);
        this.trueFalseDto.medias.forEach((el, i) =>
          this.decryptFile(el.url).then((quesUrl) => {
            this.trueFalseDto.medias[i].url = quesUrl.data;
          })
        );
      });
    }
  },
};
</script>

<style lang="scss">
.trueFalse {
  .correct-border {
    padding: 20px;
    border: 3px solid #eee;
    border-radius: 5px;

    .border-line {
      border: 3px solid #eee;

      h4 {
        border: 1px solid rgb(220, 220, 220);
        padding: 7px 15px;
        border-radius: 5px;
        margin-bottom: 0 !important;
      }
    }
  }

  .svg-delete {
    right: 0px;
    top: 25%;
  }
}
</style>
