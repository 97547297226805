<template>
  <div class="mainQuestion">
    <ValidationObserver ref="form">
      <!-- Add Main Questions -->
      <div class="main-border w-100 p-2 mb-2 rounded">
        <div class="d-flex justify-content-between">
          <label class="d-flex justify-content-between"> نص السؤال </label>
          <div class="group-btn-question d-flex justify-content-end align-items-center">
            <div class="d-flex">
              <!--type Medias-->
              <b-button
                v-for="type in types"
                :key="type.id"
                variant="primary"
                class="p-0 b-icon"
                v-b-tooltip.hover
                :title="type.title"
                @click="addMedias(questionsDto, type)"
              >
                <unicon fill="white" height="18px" :name="type.icon"> </unicon>
              </b-button>
            </div>
          </div>
        </div>
        <ek-input-text
          placeholder="السؤال"
          name="addQuestion"
          class="w-75"
          v-model="questionsDto.content"
          :rules="[
            {
              type: 'required',
              message: 'السؤال مطلوب',
            },
          ]"
        >
        </ek-input-text>
        <b-col cols="12" v-for="(media, index) in questionsDto.medias" :key="index">
          <div class="position-relative">
            <!-- Add input image for media-->
            <ek-input-image
              :required="true"
              :image="media.url"
              @input="media.file = $event.file"
            >
              <h5>ادرج الملف هنا او انقر للرفع</h5>
              <unicon
                :name="media.mediaType == 1 ? 'microphone' : 'image-plus'"
                height="12px"
              ></unicon>
            </ek-input-image>
            <!--Add button delete media-->
            <b-button
              variant="wight"
              class="position-absolute svg-delete"
              @click="deleteMedia(questionsDto, index)"
            >
              <svg
                style="cursor: pointer"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.875 7.125L7.125 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.125 7.125L11.875 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </b-button>
          </div>
        </b-col>
      </div>
      <!--Add Answers-->
      <div class="w-100 mb-2 rounded">
        <h4 for="question-text">
          إضافة إجابة
          <b-button variant="primary" class="p-0 b-icon" @click="addAnswer(questionsDto)">
            <unicon fill="white" height="18px" name="plus"> </unicon>
          </b-button>
        </h4>
        <div
          class="main-border w-100 p-1 mt-1 rounded"
          v-for="(ans, index) in questionsDto.answers"
          :key="index"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <label>{{ `الإجابة ${index + 1}` }}</label>
              <b-form-checkbox
                v-b-tooltip.hover
                title="الإجابة الصحيحة"
                class="ml-1"
                v-model="ans.isCorrect"
                name="checkbox-1"
              >
              </b-form-checkbox>
            </div>
            <div
              class="group-btn-question d-flex justify-content-between align-items-center"
            >
              <div class="d-flex">
                <!--type medias ans-->
                <b-button
                  v-for="type in types"
                  :key="type.id"
                  variant="primary"
                  class="p-0 b-icon"
                  v-b-tooltip.hover
                  :title="type.title"
                  @click="addMedias(ans, type)"
                >
                  <unicon fill="white" height="18px" :name="type.icon"> </unicon>
                </b-button>
              </div>
            </div>
          </div>
          <div class="position-relative">
            <ek-input-text
              placeholder="ادخل الاجابة"
              name="addQuestion"
              class="w-75"
              v-model="ans.content"
            >
            </ek-input-text>
            <!--Add button delete ans-->
            <b-button
              variant="wight"
              class="position-absolute svg-delete"
              @click="deleteAns(questionsDto, index)"
            >
              <svg
                style="cursor: pointer"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.875 7.125L7.125 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.125 7.125L11.875 11.875"
                  stroke="#EA5455"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </b-button>
          </div>
          <b-col cols="12" v-for="ansMedia in ans.medias" :key="ansMedia.id">
            <div class="position-relative">
              <!-- Add input image for Answers-->
              <ek-input-image
                :required="true"
                :image="ansMedia.url"
                name="image"
                @input="ansMedia.file = $event.file"
              >
                <h5>ادرج الملف هنا او انقر للرفع</h5>
                <unicon
                  :name="ansMedia.mediaType == 1 ? 'microphone' : 'image-plus'"
                  height="14px"
                ></unicon>
              </ek-input-image>

              <!--Addd button delete media-->
              <b-button
                variant="wight"
                class="position-absolute svg-delete"
                @click="deleteMedia(ans, index)"
              >
                <svg
                  style="cursor: pointer"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.875 7.125L7.125 11.875"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.125 7.125L11.875 11.875"
                    stroke="#EA5455"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </b-button>
            </div>
          </b-col>
        </div>
      </div>
      <!--Add hint-->
      <div class="shadow w-100 p-2 mt-2 rounded">
        <ek-input-text
          label="التلميح"
          name="hint"
          placeholder="اضف تلميح للسؤال"
          v-model="questionsDto.hint"
        >
        </ek-input-text>
      </div>
      <!--Add Button Footer-->

      <div class="ml-1 mt-3 d-flex justify-content-between">
        <div>
          <b-button size="md" class="ml-1" variant="primary" @click="submit">
            {{ questionId != 0 ? "تعديل" : "اضافة" }}
          </b-button>

          <b-button variant="outline-primary" class="ml-1" @click="$router.go(-1)">
            تراجع
          </b-button>
        </div>
        <div v-if="questionId != 0">
          <b-button
            variant="danger"
            class="ml-1"
            @click="deleteQuestion(questionsDto.id)"
          >
            حذف السؤال
          </b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["questionId", "quizId", "lessonId"],
  computed: {
    ...mapState({
      lessonDto: ({ lessons }) => lessons.lessonDto,
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
    }),
  },
  data() {
    return {
      questionsDto: {
        id: "",
        quizId: "",
        medias: [],
        answers: [
          {
            isCorrect: false,
            content: "",
            medias: [],
          },
        ],
        content: "",
        hint: "",
        order: "",
      },
      mainQuestions: [],
      types: [
        {
          id: 1,
          icon: "microphone",
          title: "microphone",
        },
        {
          id: 2,
          icon: "image-plus",
          title: "image",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getByIdQuestion",
      "addMainQuestion",
      "updateMainQuestion",
      "decryptFile",
      "deleteQuestion",
      "getLessonQuiz",
    ]),
    submit() {
      this.$refs.form.validate().then((succ) => {
        if (succ) {
          if (this.questionId != 0) {
            this.updateMainQuestion(this.questionsDto).then((res) => {
              this.questionsDto = {
                ...res,
              };
            });
          } else {
            this.questionsDto.order = this.quizDto.questionOrder;
            this.addMainQuestion({
              ...this.questionsDto,
            }).then((data) => {
              this.$emit("questionAdded", data);
              this.mainQuestions.push({
                ...this.questionsDto,
              });
              this.getLessonQuiz(this.quizId)
              this.questionsDto.content = "";
              this.questionsDto.hint = "";
              (this.questionsDto.medias = []),
                (this.questionsDto.answers = [
                  {
                    isCorrect: false,
                    content: "",
                    medias: [],
                  },
                ]);
              
            });
          }
        }
      });
    },
    addMedias(target, type) {
      target.medias.push({
        file: null,
        mediaType: type.id,
        thumbFile: null,
      });
    },
    addAnswer(target) {
      target.answers.push({
        isCorrect: false,
        content: "",
        medias: [],
      });
    },
    deleteMedia(index, id) {
      var md = index.medias.findIndex((el, index) => index == id);
      index = index.medias.splice(md, 1);
    },
    deleteAns(item, id) {
      let s = item.answers.findIndex((ele, index) => index == id);
      item = item.answers.splice(s, 1);
    },
  },
  created() {
    this.questionsDto.order = this.quizDto.questionOrder;

    this.questionsDto.quizId = this.quizId;
    if (this.questionId != 0) {
      this.getByIdQuestion(this.questionId).then((res) => {
        this.questionsDto = { ...res, medias: res.urls };
        this.questionsDto.medias.forEach((el, i) =>
          this.decryptFile(el.url).then((quesUrl) => {
            this.questionsDto.medias[i].url = quesUrl.data;
          })
        );

        res.answers.forEach((ans, i) => {
          ans.medias = ans.urls;
          ans.medias.forEach(({ url }, j) => {
            this.decryptFile(url).then((newUrl) => {
              this.questionsDto.answers[i].urls[j].url = newUrl.data;
            });
          });
        });
      });
    }
  },
};
</script>
<style lang="scss">
.mainQuestion {
  .main-border {
    padding: 20px;
    border: 3px solid #eee;
    border-radius: 5px;

    .border-line {
      border: 3px solid #eee;

      h4 {
        border: 1px solid rgb(220, 220, 220);
        padding: 7px 15px;
        border-radius: 5px;
        margin-bottom: 0 !important;
      }
    }
  }

  .svg-delete {
    right: 0px;
    top: 25%;
  }
}

.b-icon {
  margin-left: 3px;
}

.b-icons {
  height: 21px;
  position: relative;
  left: 3px;
}

.questions-bank {
  align-items: center;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }

  &__filter-title {
    font-weight: bold;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__filter-container {
    display: flex;
    gap: 2rem;
  }

  &__filter-by-activity-container {
    display: flex;
  }

  &__activity-filter-title {
    margin-top: 1.4rem;
    font-size: 1rem;

    color: #6e6b7b;
  }

  &__activity-filter-input {
    flex: 1;
  }

  &__filter-by-ads-status-container {
    display: flex;
  }

  &__status-filter-title {
    margin-top: 1.4rem;

    font-size: 1.2rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__status-filter-input {
    flex: 0.3;
  }
}
</style>
